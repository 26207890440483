import { P as PROFIT_BASE_SALES_FORECAST_BASE, a as PROFIT_EXISTING_BASE_SALESFORECAST } from "./maintain-versions.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "ok-text": "Add", "title": "Add New Version", "after-close": _vm.afterModalClose, "width": 520 }, on: { "ok": _vm.submit }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.onCancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.submit);
      } } }, [_vm._v("Save")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "all-in-value": "", "form-item": "", "label": "Version", "placeholder": "Select Version", "rules": "required", "source": "versionId", "source-label": "version", "data-source": _vm.versionList }, on: { "change": _vm.handleVersionChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "form-item": "", "label": "Fin Year (Cal)", "placeholder": "Select Year", "rules": "required", "source": "finYear", "data-source": _vm.finYearList }, on: { "change": _vm.onFinYearChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "form-item": "", "label": "Base", "placeholder": "Select Base", "reference": "web-analytics.common.profit-base", "rules": "required", "source": "base" }, on: { "change": _vm.onBaseChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Comment", "max-length": 40, "placeholder": "Input Comment", "rules": "required" }, on: { "change": function($event) {
      return _vm.storeValue("comment", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "form-item": "", "label": "Forecasting Cycle", "placeholder": "Select Forecasting Cycle", "reference": "web-analytics.common.profit-forecast-cycle", "source": "forecastCycle" }, on: { "change": function($event) {
      return _vm.storeValue("forecastCycle", $event);
    } } })], 1), _vm.existingBaseAliasVisible ? _c("a-col", { attrs: { "span": 24 } }, [_c("a-row", { staticClass: "select-alias-container", attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 12 } }, [!_vm.existingBaseAliasDisabled ? _c("select-input", { attrs: { "form-item": "", "label": "Existing Base Alias", "placeholder": "Select Existing Base Alias", "reference": "web-analytics.common.profit-existingbase", "source": "existingBase", "value": _vm.existingBaseAlias }, on: { "change": _vm.onExistingBaseAliasChange } }) : _c("text-input", { attrs: { "disabled": "", "form-item": "", "label": "Existing Base Alias", "value": _vm.existingBaseAlias } })], 1)], 1)], 1) : _vm._e()], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
var CreateMaintainVersions_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".select-alias-container[data-v-6c18d3d6]{background-color:#f6f6fa}\n")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "CreateMaintainVersions",
  inject: ["resourceProps"],
  data() {
    const [versionControlProps, versionProps, finYearProps] = this.resourceProps;
    return {
      versionControlProps,
      versionProps,
      finYearProps,
      apiUrl: apiUrl$1,
      base: void 0,
      existingBaseAliasVisible: false,
      existingBaseAlias: void 0,
      existingBaseAliasDisabled: false,
      finYear: void 0,
      isLoading: false,
      visible: false
    };
  },
  computed: {
    versionList() {
      return this.versionProps.crud.getList();
    },
    finYearList() {
      return this.finYearProps.crud.getList();
    }
  },
  created() {
    this.versionControlProps.crud.initEntity();
    this.fetchVersionList();
    this.fetchFinYearList();
  },
  mounted() {
    this.visible = true;
  },
  methods: {
    fetchVersionList() {
      this.fetchList(this.versionProps);
    },
    fetchFinYearList() {
      this.fetchList(this.finYearProps);
    },
    fetchList(resourceProps) {
      resourceProps.crud.deleteFilter("IsInactive");
      resourceProps.crud.fetchList(null, true, true, {}, false);
    },
    storeValue(resourceKey, value) {
      this.versionControlProps.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    handleVersionChange(version) {
      this.storeValue("version", version);
      this.finYearProps.crud.deletePagination();
      this.finYearProps.crud.setQueryString("version", version.version);
      this.fetchFinYearList();
    },
    async submit() {
      this.isLoading = true;
      try {
        await this.versionControlProps.crud.submitEntity("create");
        const pageSize = this.versionControlProps.crud.getPagination().pageSize;
        this.versionControlProps.crud.setPagination({ page: 1, pageSize });
        this.versionControlProps.crud.fetchList();
        this.isLoading = false;
        this.visible = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    afterModalClose() {
      this.versionControlProps.crud.clearEntity("createForm");
      this.$router.push(this.versionControlProps.redirectRoute);
    },
    onBaseChange(value) {
      this.base = value;
      this.storeValue("base", value);
      if (!value) {
        return;
      }
      this.existingBaseAliasVisible = true;
      if (value == PROFIT_BASE_SALES_FORECAST_BASE) {
        this.existingBaseAliasDisabled = true;
        if (!this.finYear) {
          return;
        }
        this.existingBaseAlias = this.finYear.toString().slice(-2) + PROFIT_EXISTING_BASE_SALESFORECAST;
        this.storeValue("existingBase", this.existingBaseAlias);
      } else {
        this.existingBaseAliasDisabled = false;
        this.existingBaseAlias = void 0;
      }
    },
    onCancel() {
      this.visible = false;
    },
    onExistingBaseAliasChange(value) {
      this.existingBaseAlias = value;
      this.storeValue("existingBase", value);
    },
    onFinYearChange(value) {
      this.finYear = value;
      this.storeValue("finYear", value);
      if (!this.base || this.base != PROFIT_BASE_SALES_FORECAST_BASE) {
        return;
      }
      this.existingBaseAlias = value.toString().slice(-2) + PROFIT_EXISTING_BASE_SALESFORECAST;
      this.storeValue("existingBase", this.existingBaseAlias);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "6c18d3d6", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateMaintainVersions = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "web-analytics.common.profit-base", "resource-id-name": "base", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.profit-existingbase", "resource-id-name": "existingBase", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.profit-forecast-cycle", "resource-id-name": "forecastCycle", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": ["web-analytics.version-controls", "web-analytics.common.profit-version", "web-analytics.common.profit-fin-year"], "api-url": _vm.apiUrl, "resource-id-name": ["iterationId", "version", "finYear"], "redirect-route": ["/web-analytics/maintain-versions"] } }, [_c("create-maintain-versions")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateMaintainVersions
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
